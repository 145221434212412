import React, { useState } from "react";
import "./Experience.css";

function Experience() {
  const [company, setCompany] = useState("protocase");
  return (
    <div className="main-exp">
      <h1 id="projecthead" className="head">
        Professional Experience
      </h1>
      <div className="exp-mob-div">
        <div
          className={` ${
            company === "TCS" ? "showmobilecomp" : "hidemobilecomp"
          }`}
          onClick={() => setCompany("TCS")}
        >
          <p className="comp-name">Tata Consultancy Services</p>
        </div>
        <div
          className={`${
            company === "protocase" ? "showmobilecomp" : "hidemobilecomp"
          }`}
          onClick={() => setCompany("protocase")}
        >
          <p className="comp-name">Protocase</p>
        </div>
        <div
          className={`${
            company === "infy" ? "showmobilecomp" : "hidemobilecomp"
          }`}
          onClick={() => setCompany("infy")}
        >
          <p className="comp-name">Infycloud Software</p>
        </div>
      </div>
      <div className="exp-main-div">
        <div className="left-name-div">
          <div
            className={` ${company === "TCS" ? "showcomp" : "hidecomp"}`}
            onClick={() => setCompany("TCS")}
          >
            <p className="comp-name">Tata Consultancy Services</p>
          </div>
          <div
            className={` ${company === "protocase" ? "showcomp" : "hidecomp"}`}
            onClick={() => setCompany("protocase")}
          >
            <p className="comp-name">Protocase</p>
          </div>
          <div
            className={` ${company === "infy" ? "showcomp" : "hidecomp"}`}
            onClick={() => setCompany("infy")}
          >
            <p className="comp-name">Infycloud Software</p>
          </div>
        </div>
        <div className="right-descp-div">
          <div className={` ${company === "TCS" ? "show" : "hide"}`}>
            <h3>
              <span className="heading">
                {" "}
                Assistant Software Developer @ TCS
              </span>
            </h3>
            <p className="date">August 2021 - September 2022</p>
            <div>
              <ul>
                <li className="li">
                  Refined data fetching by migrating from an internally hosted
                  monolithic backend server to AWS microservice-based endpoints,
                  resulting in a 25% increase in data retrieval efficiency.
                </li>
                <li className="li">
                  Utilized a component-based architecture in React to develop
                  reusable methods and components, which were then used across
                  multiple projects, improving development efficiency by 20%.
                </li>
                <li className="li">
                  Implemented unit and integration tests with Jest for React
                  components, ensuring high code reliability and reducing bugs
                  by 35%.
                </li>
                <li className="li">
                  Designed and delivered RESTful APIs, facilitating seamless
                  front-end and back-end communication, contributing to a 25%
                  increase in overall system efficiency.
                </li>
                <li className="li">
                  Improved an advisory tool by addressing issues with the filter
                  feature. Specifically, created optimized queries to fetch
                  accurate data and implemented proper data structuring, leading
                  to a 50% enhancement in functionality.
                </li>
              </ul>
            </div>
          </div>
          <div className={` ${company === "infy" ? "show" : "hide"}`}>
            <h3>
              <span className="heading">
                {" "}
                Software Developer Intern @ InfyCloud Software
              </span>
            </h3>
            <p className="date">September 2020 - October 2020</p>
            <div>
              <ul>
                <li className="li">
                  Experience with legacy code in a project built with HTML, CSS,
                  JavaScript, and ASP.Net
                </li>
                <li className="li">
                  Integrated more than 10 backend API endpoints with the
                  frontend.
                </li>
                <li className="li">
                  Collaboratively developed a Doctor Appointment website as part
                  of a team of 3.
                </li>
              </ul>
            </div>
          </div>
          <div className={` ${company === "protocase" ? "show" : "hide"}`}>
            <h3>
              <span className="heading">
                {" "}
                Software Developement Intern @ Protocase Inc
              </span>
            </h3>
            <p className="date">April 2024 - August 2024</p>
            <div>
              <ul>
                <li className="li">
                  Learnt JavaFX and developed a user interface screen using
                  Java, MySQL, and JavaFX within the allocated timeframe,
                  consolidating customer-related data into a single screen and
                  reducing navigation to 5 different screens.
                </li>
                <li className="li">
                  Developed a responsive task summary report page in React
                  Native, improving user task tracking by 30% and optimizing
                  performance across devices.
                </li>
                <li className="li">
                  Delivered a C# project to process objects, extract data, and
                  generate instructional strings, saving an average of 4 seconds
                  per instruction, significantly benefiting 50+ engineers
                </li>
                <li className="li">
                  Devised a Java service utilizing Watch Service to monitor a
                  specific directory and built a Slack bot to send notifications
                  upon version file updates, ensuring instant alerts for
                  multiple teams totaling over 70 members.
                </li>
                <li className="li">
                  Utilized Git and GitLab for version control and collaboration,
                  ensuring efficient code management and seamless teamwork
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experience;
